.nav-item {
  .nav-link {
    display: flex;
    align-items: center;

    &.active {
      color: #fff;
      background: rgba(255, 255, 255, 0.23);
      opacity: 1;
    }
  }
}
.cr-sidebar {
  .nav {
    margin-top: 50px !important;
    .nav-item {
      margin: 0 !important;
      .nav-link {
        color: #000 !important;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
}
.sidebar .nav a {
  transition: all 150ms ease-in;
}
.div-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.div-col {
  width: 40%;
}

.card {
  border: 2px solid #f9f8fd;
}
.card-header {
  text-transform: uppercase;
}

.badge:empty {
  display: inline-block;
}

.popover {
  z-index: 1040;
}
.page-spinner {
  align-items: center !important;
  position: relative !important;
  top: 30% !important;
  left: 50% !important;
  height: 5rem;
  width: 5rem;
}

.footer-container {
  position: absolute !important;
  bottom: 0 !important;
}

.color-black {
  color: black;
}
.font-s-12 {
  font-size: 12px;
}

.page-card {
  padding: 2rem;
  border-radius: 1rem;
}
.button-limit {
  color: #17203f !important;
}
.limit-group {
  display: inherit;
  align-items: center;
  // background: red;
  height: 90%;
}
.pagination {
  // position: absolute;
  // bottom: 10px;
  // right: 10px;
  justify-content: flex-end;
  .button-drop-down {
    height: 50px;
    width: 50px;
    border: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
  .page-link {
    height: 50px;
    width: 50px;
    border: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #28376e;
  }
  .dropdown {
    .show {
      .btn-secondary.dropdown-toggle:focus {
        color: #28376e;
      }
    }
  }
}
.center-spinner {
  position: absolute;
  left: 50%;
  top: 30%;
}
.cursor {
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
}

.header-row {
  // background-color: red;
}

.cr-page__title {
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
}
.btn-primary {
  background: #28376e;
  border-radius: 5px;
  font-size: 12px;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  height: 40px;
  padding: 10px 30px;
}
.btn-secondary {
  background: #fff !important;
  border: 2px solid #000000;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #000000;
  height: 40px;
  svg {
    font-size: 20px;
  }
}
.btn-secondary:hover {
  color: grey;
  border-color: grey;
}

.no-outline {
  border: none;
}

.box-shadow {
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
}
.box-shadow-error {
  box-shadow: 2px 2px 2px 2px red;
}
.header-button-sec {
  width: 100%;
  float: right;
}

.pull-right {
  float: right;
}

.tab {
  font-weight: bold;
}

.activeTab {
  border-bottom: 2px solid #000 !important;
}

.tab-bar {
  padding-bottom: 20px;
  padding-top: 21px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: -15px;
    right: -15px;
    bottom: -2px;
    height: 1px;
    background: #dee2e6;
  }
}
.nav-tabs {
  .nav-item {
    margin: 0 25px 0 0;
  }
  .nav-link {
    color: #949494 !important;
    cursor: pointer;
    border: none !important;
    border-radius: 0 !important;
  }
  .nav-link.activeTab {
    color: #000 !important;
    border-bottom: 2px solid #000 !important;
  }
}
.table-section-table {
  th {
    border: none !important;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #949494 !important;
    padding: 20px 15px !important;
    position: relative;
  }
  td {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    padding: 15px !important;
    vertical-align: middle !important;
    position: relative;
  }
}
.border-right {
  border-right: 1px solid grey;
}
.border-bottom {
  border-bottom: 1px solid grey;
}

.footer-button {
  flex-direction: row;
  // justify-content: space-between;
  float: right;
}
.required {
  color: red;
}
.google-places-autocomplete__suggestions-container {
  background-color: #fff;
  position: absolute;
  z-index: 2;
  box-shadow: 1px 1px 1px 1px grey;
  padding: 1rem;
  min-width: 90%;
}
.google-places-autocomplete__suggestion {
  margin-bottom: 1rem;
  // border-bottom: 1px solid gray;
}
.location-icon {
  position: absolute;
  top: 45%;
  right: 30px;
}

#navItem-signout {
  cursor: pointer;
}

.form-control-address {
  width: 100%;
  padding-left: 2%;
  padding-right: 10%;
}
#react-google-places-autocomplete-input {
  width: 100%;
  padding-left: 2%;
  padding-right: 10%;
}
.location-icon-agent {
  position: absolute;
  top: 45%;
  right: 3%;
}
.map-loader {
  height: 50vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}
.map-spinner {
  top: 40%;
  left: 50%;
  position: absolute;
}
.map-container-lanscape {
  height: 100%;
  margin-top: 0;
}
.text-orange {
  color: #ff7b01;
}
.loc-tab-bar {
  border-bottom: none;
}
.sd-btn {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  height: 40px;
  padding: 0 12px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
}

.sd-btn svg {
  font-size: 14px;
}

.btn-none:focus {
  border: none;
  box-shadow: none;
  color: #000;
}
.btn-none:active {
  border: none;
  box-shadow: none;
  color: #000;
}
.contact-row {
  margin-top: -20px;
  .row {
    svg {
      position: relative;
      top: 4px;
    }
  }
}
.cr-sidebar {
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
}
.cr-sidebar .nav .nav-link.active {
  color: #28376e !important;
}
.btn-secondary:not(:disabled):not(.disabled):active {
  color: #000 !important;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #dee2e6 #dee2e6 #fff !important;
}

.csv-btn-con {
  flex-direction: column;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #949494;
  max-width: 80%;
  padding: 20px;
  border-radius: 5px;
}
.csv-button-image {
  height: 15%;
  width: 15%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.csv-btn-label {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 80%;
  width: 80%;
}
.csv-modal {
  max-width: 40%;
  width: 40%;
  margin: 1.75rem auto;
}
.fixed-btn {
  position: fixed;
  bottom: 0;
  max-width: 96%;
  min-width: 82%;
  background-color: #fff;
}
.search-type {
  position: absolute;
  right: 0;
}
.quick-filter {
  max-width: 100%;
  height: 30px;
  font-size: 12px;
}
.table thead th {
  vertical-align: top !important;
}
.visit-td {
  width: 130px;
}
.dropBtn .btn-group {
  width: 100%;
}
.filter-btn {
  padding-left: 0 !important;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #000;
}
.d-table {
  overflow: auto;
  width: 100%;
}
.w-100 {
  width: 100%;
}
.date-range-modal {
  max-width: fit-content;
  .rdrTimePicker {
    max-height: 200px;
    overflow-y: scroll;
    position: absolute;
    background: #fff;
    z-index: 1;

    .time {
      min-width: 150px;
      padding: 0 15px;
      line-height: 40px;
      border: 0;
      background-color: #f1f3f4;

      &.active {
        background-color: #f1f3f4;
      }

      &:hover {
        background-color: #e8eaeb;
      }
    }
  }
}
@media (min-width: 576px) {
  .template-modal {
    max-width: 60%;
    margin: 1.75rem auto;
  }
  .csv-modal {
    max-width: 40%;
    width: 40%;
    margin: 1.75rem auto;
  }
}
.triangle-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid #28376e;
  margin-left: 6px;
}
.tooltip-body {
  background-color: #28376e;
  color: 'white';
  padding: 4px;
  border-radius: 5px;
}

.react-tagsinput {
  border: 1px solid #ced4da;
}
.react-tagsinput-input {
  border: none;
}
.react-tagsinput-input:focus {
  outline: none !important;
}

.react-tagsinput-tag {
  background-color: #28376e;
  color: #fff;
  padding: 0 5px;
  margin-left: 4px;
}
.border-none {
  border: none;
}
.border-none:focus {
  border: none;
  outline: none !important;
}

.timeline-container {
  width: 50px;
}

.timeline-line {
  background-color: #4f95ca;
  width: 10%;
  height: 100%;
  margin-left: 25%;
  top: -5px;
  position: relative;
}
.timeline-line-dark {
  background-color: #28376e !important;
}
.timeline-container {
  position: relative;
}
.time-bubble {
  // padding: 0.2rem;
  width: 37px;
  height: 37px;
  line-height: 37px;
  border-radius: 50%;
  // font-size: 50px;
  color: #fff;
  text-align: center;
  background: #4f95ca;
  position: absolute;
  z-index: 2;
  left: -4px;
  top: -5px;
}
.time-bubble-dark {
  background-color: #28376e !important;
}
.bubble-text {
  text-align: center;
}
.secondary-alt {
  background-color: #fff;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
}
.font-weight-600 {
  font-weight: 600;
}

.btnFont {
  font-size: 30px;
  top: -5px;
}

.location-icon-filter {
  position: absolute;
  bottom: 24px;
  right: 25px;
}

.relative-sec {
  position: relative;

  .location-icon-filter {
    position: absolute;
    bottom: 8px;
    right: 15px;
  }
}

.fillfree {
  display: flex;

  justify-content: space-between;
}

.react-dropdown-select {
  position: relative;
  .react-dropdown-select-dropdown-handle {
    margin: 0 !important;
    right: 0;
    position: relative;
    top: 2px;
    transform: rotate(180deg) !important;
  }
}

.thead-th {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #949494 !important;
  padding: 20px 15px !important;
  position: relative;
  input[type='checkbox'] {
    left: inherit;
    top: inherit;
    transform: inherit;
    position: relative;
    margin: 0;
  }
}
.form-check-input {
  margin-top: 0 !important;
}

.tbody-td {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  padding: 15px !important;
  vertical-align: middle !important;
  position: relative;
  // min-width: 150px !important;
  input[type='checkbox'] {
    left: inherit;
    top: inherit;
    transform: inherit;
    position: relative;
    margin: 0;
  }
}

.deleteBtn {
  font-size: 20px;
}

input[type='checkbox'] {
  border-radius: 5px !important;
  width: 22px;
  height: 22px;
  background: #d6d6d6 !important;
  border: none !important;
}

.ButtonGroup__root {
  .btn-primary {
    border: 1px solid #f0f0f0 !important;
    opacity: inherit !important;
    height: 50px;
    color: #fff !important;
  }
  .btn-primary.disabled,
  .btn-primary:disabled {
    background: #ffffff !important;
    border: 1px solid #f0f0f0 !important;
    opacity: inherit !important;
    color: #28376e !important;
    height: 50px;
  }
}

.range-slider__tooltip__label {
  .tooltip-body {
    min-width: 30px;
    text-align: center;
  }
}

.fill-color {
  color: #adadad;
}

.cr-content {
  width: calc(100vw - 240px) !important;
}

.newModal.modal-dialog {
  max-width: 600px !important;
}
.react-dropdown-select-input {
  // display: none;
  // width: 30%;
  width: auto;
}

.td-width {
  width: 500px;
}

.fill-check {
  .form-check-label {
    padding-left: 15px;
    span {
      position: relative;
      padding-left: 10px;
      top: 5px;
    }
  }
}
.widSec1 h4 {
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.relative-class {
  position: relative;
  padding-bottom: 20px;
  .noBtn {
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}
.email-text {
  align-items: flex-start !important;
}
.email-text .text-info {
  max-width: 350px;
  word-break: break-all;

  // overflow: hidden;
  // text-overflow: ellipsis;
}
.template-item:hover {
  background-color: rgba(0, 116, 217, 0.1);
}
.template-dropdown-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .template-content {
    position: relative;
    top: 5px;
  }
}
.range-slider__tooltip__label .tooltip-body {
  margin-left: -4px;
}
.tbody-td {
  max-width: 300px;
}
.btn-danger {
  border-radius: 5px;
  font-size: 12px;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  height: 40px;
  padding: 10px 30px;
}
.no-break {
  white-space: pre-wrap;
  word-break: break-word;
}

.add-stop {
  .react-dropdown-select-content {
    input {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.button-drop-down {
  height: 50px;
  width: 50px;
  border: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.buttonGroup__root {
  .page-link {
    height: 50px;
    width: 50px;
    border: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
  .page-item.active .page-link {
    background: #28376e;
    color: #fff;
  }
}
td {
  font-weight: bold !important;
}
.border-right {
  border-right: 1px solid grey;
}
.contact-form {
  min-width: 60%;
}
.visited-location-modal {
  width: 100%;
  min-width: 90% !important;
}
.page-card {
  padding: 20px;
  overflow: auto;

  table {
    .form-check-input {
      position: relative !important;
      margin-left: -10px;
      top: 2px;
    }
  }
  .col-9 {
    .row {
      .col {
        p {
          font-size: 12px;
        }
        .btn-group {
          button {
            font-size: 12px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1366px) {
  .thead-th {
    padding: 15px 5px !important;
    font-size: 12px;
  }
  .tbody-td {
    font-size: 12px;
    padding: 15px 5px !important;
  }
  .btn-primary {
    padding: 10px 15px;
  }
  .footer-button.mr-4 {
    margin-right: 10px !important;
  }
  .page-card .text-muted {
    font-size: 12px;
  }
  .page-card .font-weight-bold {
    font-size: 12px;
  }
  .page-card h4 {
    font-size: 18px;
  }
  .pl-3 {
    padding-left: 10px !important;
  }
  .text-info {
    font-size: 12px;
  }
  .email-text .text-info {
    max-width: 200px;
  }
  .page-card {
    padding: 20px;
  }
  .dropdown-item {
    font-size: 12px;
  }
  .cr-content .col {
    font-size: 12px !important;
  }
  .cr-content .col div {
    font-size: 12px !important;
  }

  .tbody-td {
    .text-justify {
      .btn {
        margin-bottom: 10px;
      }
    }
  }
}
.react-dropdown-select-dropdown {
  width: 100% !important;
  max-height: 150px !important;
}
.div-col-chart {
  width: 100%;
  // height: 20px;
}
.date-range-input {
  width: 100% !important;
}
.select-dropdown {
  max-height: 60vh;
  overflow: auto;
}

.datepicker {
  width: 100%;
}
