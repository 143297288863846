.chat-body {
  height: 45vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.user-image {
  object-fit: cover;
}

.chat-bubble {
  text-align: right;
  .bubble {
    max-width: 95%;
    display: inline-block;
    border-radius: 0.5rem;
    background-color: #f5f5f5;
    border-top-right-radius: 0px;
    span {
      padding-left: 5px;
    }
  }
  &.is-user {
    text-align: left;
    .bubble {
      background-color: #b3e5fc;
      border-top-left-radius: 0px;
    }
  }
  p {
    font-size: 0.9rem;
    margin-bottom: 0px;
  }
  span {
    font-size: 0.5rem;
  }
}

.gif-container {
  height: 70vh;
  overflow-y: auto;
  button.btn {
    background: transparent;
    border: 0;
    margin: 0.5rem;
    padding: 0;
    &:focus {
      outline: 5px solid #29b6f6;
    }
  }
  img {
    max-width: 190px;
    height: auto;
  }
}
.selected-chat {
  border: 2px solid red;
}
.profile-details {
  list-style: none;
  margin: 0 !important;
  padding: 0 !important;
  li {
    span {
      margin-left: 5px;
    }
  }
}

.gift-btn {
  position: relative;
  span {
    position: absolute;
    bottom: 2%;
    box-shadow: 0px 0px 5px #bdbdbd;
    left: 50%;
    transform: translate(-50%, -0%);
    background-color: #f9f9f9;
    padding: 0.5rem 0.5rem;
    border-radius: 0.2rem;
  }
}

.inbox {
  height: 40vh;
  overflow-y: auto;
}
.notes {
  height: 25vh;
  overflow-y: auto;
  .note {
    width: 100%;
    display: inline-block;
    p {
      width: 100%;
    }
    .animator-name {
      font-size: 0.8rem;
    }
  }
}
.user-img {
  height: 150px;
  width: 100%;
  object-fit: contain;
  background-color: #eee;
}
.unread {
  border: 2px solid blue;
}
