// custom-scroll-design
*::-webkit-scrollbar {
  height: 4px;
  width: 6px;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #e3e3e3;
  border-radius: 10px;
  outline: 1px solid 'transparent';
}

//  Pagination
.pagination {
  margin-bottom: 0;
}

.page-item.active .page-link {
  background-color: #28376e !important;
}

// Table
.table-td-content {
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  white-space: nowrap;
  font-weight: medium;
}

.table-footer-content {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  padding-top: 14px;
  flex-wrap: wrap;
}

.per-page-content {
  font-weight: 600;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.total-item-content {
  font-weight: 600;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.pagination-content {
  display: flex;
}

.table-th {
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.table-th-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // min-height: 60px;
}

.table-th-content-height {
  min-height: 60px;
}


.table-th-content-child {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}

.table-tr {
  background-color: #fff;
  border-style: solid;
  border-width: 2px 0;
  border-color: #f0f0f0;
}

.table-body-tr {
  border-style: solid;
  border-width: 0 0 1px;
  border-color: #f0f0f0;
}

.table-body-tr:hover {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}

.table-body-td {
  padding: 8px;
}

.table-body-td-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 60px;
}

.table-section {
  overflow: auto;
  min-height: 300px;
}

.table-section-table {
  width: 100%;
}

.table-section-input {
  margin-bottom: 0.75rem;
}

.table-head-children {
  margin-right: 8px;
  cursor: pointer;
}

.table-no-data {
  width: 100%;
  text-align: center;
  padding: 20px;
  font-weight: 600;
  min-height: 300px;
}

//Agent Map
.agent-map-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 10px;
  margin-bottom: 10px;
}

.flex-1 {
  flex: 1;
}

.load-more{
  background-color: #f0f0f0;
  width: 100%;
  border: none;
  padding: 10px;
  position: relative;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.load-more:hover {
  background-color: #e3e3e3 ;
}

.agent-card,
.office-card {
 min-height: 380px;
}

.location-card {
  min-height: 500px;
}

.select-menu {
  position: absolute;
  width: 100%;
  z-index: 110;
  background-color: white;
}

.location-search-header {
  display: flex;
  align-items: center;
}

.quick-search {
  margin-left: 1rem;
}

.flex {
  display: flex;
  width: 100%;
}

.table-col-with-ellipsis {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select__multi-value__remove svg {
  color: white !important;
}

.select__multi-value__remove:hover {
  background-color: transparent !important;
}

.min-w-250 {
  min-width: 250px;
}

.min-w-170 {
  min-width: 170px;
}

.border-top-0 {
  border-top: 0;
}

.padding-top-0 {
  padding-top: 0 !important;
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.text-xl {
  font-size: 18px;
  font-weight: 500;
}

.font-bold {
  font-weight: bold;
}

.text-blue-700 {
  color: #28376E;
}

.SortableItem {
  list-style: none;
}

.cursor-grab {
  cursor: grab;
}

.m-auto {
  margin: auto;
}
